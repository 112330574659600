@import '../statics/styles/variables';
@import '../statics/styles/mixins';

.fingerprint-wrapper {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;

  img {
    width: 100px;
    height: 120px;
    cursor: pointer;
  }

  div {
    cursor: pointer;
    width: 100px;
    height: 120px;
    border: 2px solid;
    text-align: center;
    padding-top: 16px;
  }

  .pi-spinner {
    &::before {
      font-size: 80px;
    }
  }

  span {
    position: absolute;
    bottom: 0;
    color: #fff;
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 12px;
  }
}

@include media-breakpoint-down('xs') {
  .fingerprint-wrapper {
    img {
      width: 50px;
      height: 60px;
      cursor: pointer;
    }
  }
}
