.selected-chat-wrapper {
  width: 400px;
  height: 500px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 2;
  border: 1px solid #cccccc;
  border-radius: 10px 10px 0 0;

  ul {
    list-style: none;
  }

  .chat-avatar-group {
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    float: left;
    .chat-avatar {
      width: 100%;
    }
  }

  .s-chat-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 18px;
    border-bottom: 1px solid #cccccc;
    background-color: #cccccc;
    border-radius: 10px 10px 0 0;

    .s-chat-close {
      i {
        cursor: pointer;
      }
    }
  }

  .s-chat-content {
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 50px - 80px);
    overflow: hidden;
    min-height: 1.25em;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    background-color: #fff;
    padding: 10px;
    border-bottom: 2px solid white;
    overflow-y: scroll;

    .message-data {
      margin-bottom: 15px;
    }

    .system-message {
      text-align: center;
    }

    .message-flex {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        &:first-child,
        div,
        small {
          width: calc(100% - 30px);
        }
        display: flex;
        align-items: center;

        small {
          display: block;
        }

        span {
          margin-right: 10px;
        }
      }
    }

    .message {
      color: #000;
      padding: 10px;
      line-height: 20px;
      font-size: 14px;
      border-radius: 7px;
      margin-bottom: 10px;
      position: relative;

      &.highlight {
        background-color: rgb(128, 213, 1);
      }

      .message-data-time {
        font-size: 10px;
        color: #666;
      }

      .message-full-name {
        font-size: 10px;
        color: red;
        text-decoration: underline;
      }

      .quotes-group {
        position: absolute;
        bottom: 10px;
        left: -55px;
        right: auto;
        background-color: #eeeeee;
        padding: 5px 15px;
        border-radius: 10px;
        display: none;

        img {
          width: 20px;
          cursor: pointer;
        }
      }

      &.other-message {
        .quotes-group {
          left: auto;
          right: -55px;
        }
      }

      .reaction-icon {
        position: absolute;
        bottom: -8px;
        right: -6px;
        cursor: pointer;
        border: 1px solid #cccccc;
        border-radius: 10px;
        background: #ffff;
        display: none;
        span {
          padding: 1px 3px;
          font-size: 17px;
        }
      }

      .reacted-icons {
        text-align: right;
      }
    }

    li:hover {
      .message {
        .quotes-group {
          display: block;
        }
        .reaction-icon {
          display: block;
        }
      }
    }

    .my-message {
      background: #c4e3ff;
      text-align: right;
    }

    .other-message {
      background: #eeeeee;
    }

    .message-avatar {
      display: flex;
      align-items: flex-start;

      .message-content {
        max-width: calc(100% - 60px);
      }

      .empty-avatar {
        width: 40px;
        height: 40px;
      }

      &.mine {
        .chat-avatar-group,
        .empty-avatar {
          margin-left: 20px;
        }
      }
      &.other {
        .chat-avatar-group,
        .empty-avatar {
          margin-right: 20px;
        }
      }
    }
  }

  .s-chat-input {
    height: 80px;
    overflow: auto;
    .chat-message-input {
      border: solid #d1dbe3;
      border-width: 1px 0 0;
      margin-top: auto;
      color: rgba(0, 0, 0, 0.87);
      background-color: #c6e3fa;
      padding: 10px 15px;
      flex-grow: 1;
      border-top: 0;

      .cs-file-input {
        font-size: 14px;
        padding-bottom: 5px;
        i {
          cursor: pointer;
          color: #2b6a9b;
        }
      }

      .cs-message-input {
        box-sizing: border-box;
        position: relative;
        margin: 0;
        flex-grow: 1;
        display: flex;

        textarea {
          width: 100%;
          border: none;
          padding: 5px;
          font-size: 14px;
          border-radius: 5px;
          resize: none;
          outline: none;
          box-shadow: none;
          padding: 0 !important;
        }

        .p-button {
          padding: 5px;
          background-color: transparent;
          border: none;
        }
      }
    }
  }

  .scrollbar-container {
    position: relative;
    height: 100%;
  }

  .ps {
    overflow: hidden !important;
    overflow-anchor: none;
    touch-action: auto;
  }

  .cs-message-input__content-editor-container {
    box-sizing: border-box;
    position: relative;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    overflow-y: auto;
    font-size: 0.94em;
    line-height: 1.35em;
    min-height: 30px;
    scroll-padding: 0;
    width: 100%;
    font-size: 14px;
    padding: 6px;
    border-radius: 10px;
  }

  .reply-message {
    background-color: rgb(223, 223, 223);
    padding: 10px;
    border-radius: 15px;
    font-size: 12px;
    color: #6d6b6b;
    position: relative;
    text-align: left;
    cursor: pointer;

    .reply-content {
      padding: 5px 10px;
      border-left: 2px solid red;

      .preview-img {
        width: 100px;
        height: auto;
      }
    }

    .close-reply-msg {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
}
