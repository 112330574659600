@import '../statics/styles/variables';
@import '../statics/styles/mixins';

.header {
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #e3f2fd;
  min-height: 55px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow: auto;

  h1 {
    color: $logo-color;
    margin-left: 20px;
    @include fontSize(25px);
    margin-top: 0.5rem;
  }

  .navbar-nav {
    width: 90%;
    overflow: auto;
    flex-flow: wrap;
    hr {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .nav-link {
    @include fontSize(13px);
    color: #ffffff !important;
    margin: 3px 8px;
    padding: 4px 8px !important;
    border-radius: 4px;
    background-color: #007ad9 !important;

    &:hover {
      // text-decoration: underline;
      background-color: #30a2fa !important;
    }

    &.active {
      background-color: #e91224 !important;
    }
  }

  .navbar-collapse {
    z-index: 2;
    background: #e3f2fd;
  }

  .navbar-toggler {
    margin-right: 1rem;
  }

  .login-btn {
    border: 1px solid #007ad9;
    border-radius: 5px;
    padding: 5px 10px;

    &:hover {
      text-decoration: none;
    }
  }

  .logo {
    height: 40px;
    text-align: center;

    img {
      width: auto;
      height: 100%;
    }
  }

  .logged-name-container {
    padding-right: 20px;

    .logged-name {
      margin-right: 10px;
    }

    .navbar-text {
      padding-left: 20px;

      a {
        &:hover {
          text-decoration: none;
        }
      }
    }

    .logout-link {
      display: flex;
      align-items: center;
    }
  }

  .user-profile-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 38px;
    height: 38px;
    overflow: hidden;
    z-index: 2;

    span {
      display: block;
      width: 38px;
      height: 38px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #bfbfbf;

      &:hover {
        border-color: #1a2dff;
      }

      img {
        width: 38px;
        height: 38px;
        margin-top: -1px;
        margin-left: -1px;
        border: none;
      }
    }
  }

  .chats-icon {
    position: absolute;
    top: 10px;
    right: 55px;
    width: 38px;
    height: 38px;
    overflow: hidden;
    z-index: 2;

    .pi-comments {
      display: block;
      width: 38px;
      height: 38px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      color: #fff;
      font-size: 15px;
      padding-top: 11px;
      background-color: rgb(255, 87, 87);
      position: relative;

      &:hover {
        border-color: #1a2dff;
      }

      .chat-badges {
        width: 18px;
        height: 18px;
        background-color: #007ad9;
        border-radius: 50%;
        color: #ffffff;
        font-weight: bold;
        top: 0;
        padding-top: 3px;
        right: 0;
        font-size: 9px;
        position: absolute;
      }
    }
  }

  .notification-icon {
    position: absolute;
    top: 10px;
    right: 100px;
    width: 38px;
    height: 38px;
    overflow: hidden;
    z-index: 2;

    .pi-bell {
      display: block;
      width: 38px;
      height: 38px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      color: #fff;
      font-size: 15px;
      padding-top: 11px;
      background-color: rgb(255, 87, 87);
      position: relative;

      &:hover {
        border-color: #1a2dff;
      }

      .chat-badges {
        width: 18px;
        height: 18px;
        background-color: #007ad9;
        border-radius: 50%;
        color: #ffffff;
        font-weight: bold;
        top: 0;
        padding-top: 3px;
        right: 0;
        font-size: 9px;
        position: absolute;
      }
    }
  }
  .pending-owner-header {
    position: relative;

    .pending-owner-badges {
      display: inline-block;
      background-color: red;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      font-size: 10px;
      line-height: 20px;
      top: -8px;
      right: -18px;
    }
  }
}

.user-profile-overlay {
  width: 370px;

  .p-overlaypanel-content {
    padding: 0;
    a {
      color: $primary-color;
    }
  }

  &::before {
    right: 8px;
    left: auto !important;
  }

  &::after {
    right: 8px;
    left: auto !important;
    border-bottom-color: $highlight-color;
  }

  .menu-header,
  .menu-footer {
    background-color: $highlight-color;
    color: $secondary-color;
    padding: 10px;
    cursor: pointer;

    a {
      color: $secondary-color;
    }
  }

  .menu-header {
    font-weight: bold;
  }

  .item-group {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .menu-item {
    @include fontSize(13px);
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &:hover {
      background-color: #f5f5f5;
    }

    .item-group {
      span {
        margin-left: 10px;
      }
    }
  }
}

.chat-groups-overlay {
  width: 400px;

  .p-overlaypanel-content {
    padding: 10px !important;
    a {
      color: $primary-color;
    }
  }

  &::before {
    right: 8px;
    left: auto !important;
  }

  &::after {
    right: 8px;
    left: auto !important;
    border-bottom-color: $highlight-color;
  }

  .chat-btn-tabs {
    width: 100%;
    > .p-button {
      width: 25%;
      height: 30px;
      border-radius: 0;
      border: 1px solid #ccc;
      font-size: 9px;
      outline: none !important;
      box-shadow: none !important;
      padding: 0 5px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 8px;
        font-weight: bold;
      }

      .unread-badge {
        background-color: red;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-left: 3px;
        text-align: center;
        padding: 3px 0px;
      }
    }
  }

  #chat-overlay-groups {
    height: 300px;
    overflow: auto;

    li {
      padding: 5px 10px;
      cursor: pointer;
      background-color: #d5e7f5;
      border-bottom: 1px solid #ccc;

      .list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .unread-count {
          background-color: red;
          color: white;
          border-radius: 50%;
          padding: 5px 0;
          width: 20px;
          height: 20px;
          text-align: center;
          font-size: 8px;
        }
      }

      .chat-avatar-group {
        width: 40px;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        float: left;
        .chat-avatar {
          width: 100%;
        }
      }

      .item-wrapper {
        .about {
          padding-left: 50px;
          .name {
            font-size: 13px;
            flex: 1;
            overflow: hidden;
          }
          .last-message,
          .members-icon {
            font-size: 10px;
            font-style: italic;
            overflow: hidden;
          }
        }
      }

      .item-group {
        display: flex;
        align-items: center;

        .new-message {
          background-color: red;
          margin-left: 10px;
          border-radius: 50%;
          padding: 0 5px;
          color: #fff;
          font-size: 12px;
          margin-right: 10px;
        }
      }
      .status {
        color: #92959e;
      }
    }
  }
}

.notification-container {
  .notification-row {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #333333;
    > div:first-child {
      margin-right: 50px;
      border: 1px solid #333333;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      padding-top: 4px;
    }
  }
}

@include media-breakpoint-down('xs') {
  .header {
    .navbar-nav {
      width: 100%;
    }
    .nav-link {
      border-radius: 0;
    }
  }
}
