@import '../statics/styles/variables';
@import '../statics/styles/mixins';

.notification-wrapper {
  .notification-bar {
    background-color: #f6cf70;
    font-size: 14px;
    padding: 5px;

    .notification-item {
      margin-right: 30px;
      cursor: pointer;
      &.odd {
        color: red;
      }
    }

    .overlay {
      display: none;
    }
  }
  .notification-regions {
    display: flex;
    font-size: 12px;
    overflow: auto;
    width: 100%;
    justify-content: space-between;

    .region-items {
      margin-right: 30px;
      text-align: center;
      border: 1px solid #000;
      font-weight: 500;

      &:first-child {
        margin-left: 30px;
      }

      .region-title {
        padding: 3px;
        cursor: pointer;
      }

      .region-child {
        display: flex;
        justify-content: center;

        > div {
          padding: 3px 5px;
          border-top: 1px solid #000;
          width: 150px;
          cursor: pointer;

          &:not(:last-child) {
            border-right: 1px solid #000;
          }
        }
      }

      &.north {
        background-color: yellow;
      }
      &.middle {
        background-color: rgb(73, 176, 223);
      }
      &.south {
        background-color: rgb(83, 199, 66);
      }
      &.west {
        background-color: rgb(204, 140, 187);
      }
      &.west-south {
        background-color: rgb(211, 126, 102);
      }
    }
  }
}
