@import 'variables';
@import 'mixins';

body {
  /* FORM FIELD */
  .form-group {
    width: 100%;

    &.none-border-fields {
      .p-inputgroup {
        input {
          border: none;
          border-radius: 0;
        }

        .input-label,
        .input-unit {
          line-height: 22px;
          height: 20px;
          vertical-align: center;
        }

        .input-label {
          padding-right: 7px;
        }
      }
    }

    .form-control {
      height: auto;
      @include fontSize(14px);
      padding: 0;
    }

    .p-inputgroup {
      position: relative;

      input {
        display: block;
        width: 100%;
        padding: 0.6rem 1.6rem;
      }

      .icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        &.eye-icon {
          display: flex;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }

    .input-feedback {
      color: $error-text-color;
      @include fontSize(10px);
      text-align: right;
      padding: 0;
      text-transform: none;
    }

    .form-buttons {
      margin-top: 10px;
      padding-right: 5px;
      display: flex;
      justify-content: space-evenly;
    }
  }

  .p-component {
    font-family: Arial, Helvetica, sans-serif;
  }

  .p-datatable {
    @include fontSize(12px);

    .p-paginator {
      background-color: #ffffff;
      .p-paginator-current {
        margin-left: auto;
      }
    }

    .p-datatable-header {
      padding: 1rem;
      text-align: left;
      font-size: 1.5rem;
      color: $highlight-color;
      background-color: #ffffff;

      .table-header {
        display: flex;
        justify-content: space-between;

        .p-input-icon-left {
          border: 1px solid #ccc;
          background-color: #fff;
          display: flex;
          align-items: center;
          border-radius: 6px;
          i {
            padding-left: 8px;
          }

          input {
            border: none;
            background-color: transparent;
            outline: none;
            box-shadow: none;
          }
        }

        .p-dropdown {
          width: 150px;
          text-align: left;
        }
      }
    }

    .p-datatable-scrollable-wrapper {
      overflow: hidden;
    }

    .p-datatable-scrollable-view {
      overflow: auto;
    }

    .p-datatable-wrapper {
      overflow: auto;
    }

    .p-paginator {
      padding: 0;
    }

    .p-datatable-thead > tr > th {
      text-align: left;
      background-color: #e3f2fd;
    }

    .p-datatable-tbody > tr > td {
      cursor: auto;
    }

    .p-datatable-tbody > tr:nth-child(even) {
      background-color: #f0f0f0;
    }
  }

  .p-paginator {
    background-color: #ffffff;
    .p-paginator-current {
      margin-left: auto;
    }
  }

  .p-dropdown-filter-container {
    width: 100%;
    .p-dropdown-filter.p-inputtext {
      width: 100%;
    }
  }

  /* Responsive */
  .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  .registered-props-dialog {
    width: 80%;
  }

  .p-overlaypanel {
    .p-overlaypanel-close {
      .p-overlaypanel-close-icon {
        @include fontSize(12px);
      }
    }
  }

  .p-overlaypanel-close {
    background-color: #007ad9 !important;
    color: $secondary-color;
    width: 1.538em;
    height: 1.538em;
    line-height: 1.538em;
    text-align: center;
    position: absolute;
    top: -0.769em;
    right: -0.769em;
    border-radius: 50%;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;

    .p-overlaypanel-close-icon {
      @include fontSize(12px);
    }
  }

  .p-dialog {
    .p-dialog-title {
      color: $highlight-color;
      @include fontSize(16px);
    }

    .p-dialog-titlebar-close {
      box-shadow: none;

      .p-dialog-titlebar-close-icon::before {
        color: red;
      }
    }
  }

  .p-slider {
    &.p-slider-horizontal {
      height: 10px;
      cursor: pointer;
    }

    .p-slider-handle {
      width: 20px;
      height: 20px;
      margin-top: -10px !important;
    }
  }

  .p-button {
    @include fontSize(12px);
    line-height: 14px;
    &.p-button-text-only {
      .p-button-text {
        padding: 0.3em 0.8em !important;
      }
    }

    &.p-button-transparent {
      background-color: transparent;
      border: 1px solid #333333;
      box-shadow: none;
      color: #333333;
    }
  }

  .p-inputtext:enabled:focus {
    box-shadow: none;
  }
}

@media screen and (max-width: 960px) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);

        > td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down('xs') {
  body .registered-props-dialog {
    width: 98%;
  }
}
