@import '../statics/styles/variables';
@import '../statics/styles/mixins';

.login-container {
  margin: auto;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url('../statics/images/background.png');
  background-size: 100% 100%;
  @include fontSize(16px);

  .form-field-group {
    margin-bottom: 20px;

    .p-inputgroup {
      input {
        @include fontSize(16px);
        font-size: 15px;
        line-height: 1.5;
        color: #666666;
        display: block;
        width: 100%;
        background: #e6e6e6;
        height: 50px !important;
        border-radius: 25px;
        padding: 0 30px 0 30px !important;
        box-shadow: none;
        outline: none;

        &:focus {
          background: #e6e6e6;
          border-color: #57b846;
          box-shadow: none;
        }

        &:focus::placeholder {
          color: transparent;
        }
      }
    }
  }

  .left-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-ctn {
    margin-top: 100px;
    text-align: center;

    .short-title {
      font-size: 60px;
      font-family: $title-font;
      color: $logo-color;
      display: block;
      margin-bottom: 0;
      line-height: 1;
      padding-top: 11px;
      border: 4px solid;
      width: 87px;
      margin: auto;
    }

    .title-2 {
      color: $logo-color;
      display: block;
      margin-bottom: 20px;
    }

    .title {
      font-size: 60px;
      font-family: $title-font;
      color: $logo-color;
      display: block;
      margin-bottom: 30px;
    }
  }

  .signin-btn {
    width: 100%;
    margin: 10px 0;
    border-radius: 25px;
    font-size: 15px;
    line-height: 1.5;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    background-color: #57b846;
    border-color: #57b846;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;

    &:hover {
      background-color: #333333 !important;
      border-color: #333333 !important;
    }

    &:disabled {
      background-color: #cccccc !important;
      border-color: #cccccc !important;
    }
  }

  .forgot-password-link {
    display: flex;
    justify-content: space-between;
    margin: 10px 10px;
    a {
      font-size: 13px;
      line-height: 1.5;
      color: #666666;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@include media-breakpoint-down('xs') {
  .login-container {
    .logo-ctn {
      .short-title {
        font-size: 30px;
        padding-top: 11px;
        width: 47px;
        margin: auto;
      }
      .title {
        font-size: 40px;
      }
    }
  }
}
